var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-wallet-proceed-button"},[(!_vm.isMobile)?_c('v-popover',{attrs:{"disabled":!_vm.isDisabled,"open-class":"tooltip__popover--open","placement":"bottom","popover-arrow-class":"hidden","popover-class":"tooltip__popover","popover-inner-class":"tooltip__popover-content","trigger":"hover"}},[_c('button',{staticClass:"my-wallet-proceed-button__button",class:{
        'my-wallet-proceed-button__button--disabled': _vm.isDisabled,
        'my-wallet-proceed-button__button--enabled': !_vm.isDisabled,
        'my-wallet-proceed-button__button--black-version': _vm.isBlackVersion,
        'my-wallet-proceed-button__button--green-version': !_vm.isBlackVersion,
        'font-semibold text-base': _vm.showAsterisk,
      },on:{"click":_vm.onClickDesktopAction}},[_c('span',[_vm._v(" "+_vm._s(_vm.titleText)+" ")]),(_vm.showArrow)?_c('em',{staticClass:"text-2xl fas fa-angle-right",class:{
          'text-regent-gray': _vm.isDisabled,
          'text-aquamarine': !_vm.isDisabled,
        }}):_vm._e(),(_vm.showAsterisk && _vm.isDisabled)?_c('img',{staticClass:"h-2 w-2 flex items-center-center mb-px",attrs:{"alt":"asterisk icon","src":require("@/assets/img/asterisk.svg")}}):_vm._e()]),_c('template',{slot:"popover"},[(_vm.isDisabled)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.popoverText)}}):_vm._e()])],2):_c('button',{staticClass:"my-wallet-proceed-button__button",class:{
      'my-wallet-proceed-button__button--disabled': _vm.isDisabled,
      'my-wallet-proceed-button__button--enabled': !_vm.isDisabled,
      'my-wallet-proceed-button__button--black-version': _vm.isBlackVersion,
      'my-wallet-proceed-button__button--green-version': !_vm.isBlackVersion,
      'font-semibold text-base': _vm.showAsterisk,
    },on:{"click":_vm.onClickMobileAction}},[_c('span',[_vm._v(_vm._s(_vm.titleText))]),(_vm.showAsterisk && _vm.isDisabled)?[(_vm.openMobileDisclaimer)?_c('img',{staticClass:"h-3 w-3 flex items-center-center mb-px",attrs:{"alt":"caret icon","src":require("@/assets/img/caret-up.svg")}}):_c('img',{staticClass:"h-3 w-3 flex items-center-center mb-px",attrs:{"alt":"asterisk icon","src":require("@/assets/img/asterisk.svg")}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }