



















































































import {Component} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {NeoHelper} from '@/helpers/NeoHelper'
import {$} from '@/facade'
import {ClaimGasStep} from '@/enums/ClaimGasStep'
import {InvokeParams} from '@/model/wallets/types/WalletTypes'
import ClaimGasLoadingTransactionStep from '@/components/claimGas/ClaimGasLoadingTransactionStep.vue'
import ClaimGasUnexpectedErrorStep from '@/components/claimGas/ClaimGasUnexpectedErrorStep.vue'
import ClaimGasSuccessStep from '@/components/claimGas/ClaimGasSuccessStep.vue'

@Component({
  components: {
    ClaimGasSuccessStep,
    ClaimGasUnexpectedErrorStep,
    ClaimGasLoadingTransactionStep,
  },
  computed: {
    ClaimGasStep() {
      return ClaimGasStep
    },
  },
})
export default class MyWalletClaimGasModal extends MixinScreenSize {
  currentStep: ClaimGasStep = ClaimGasStep.WAITING_SIGNATURE

  get canBeClosed() {
    return this.currentStep !== ClaimGasStep.WAITING_SIGNATURE
  }

  async openEvent() {
    this.claimGas()
  }

  async closeEvent() {
    this.currentStep = ClaimGasStep.WAITING_SIGNATURE
    this.$modal.close('myWalletClaimGasModal')
  }

  async claimGas() {
    this.currentStep = ClaimGasStep.WAITING_SIGNATURE

    try {
      if (!$.walletAdapter.n3Address) throw new Error()

      const invokeParams: InvokeParams = {
        method: 'claimGasInvocation',
        params: $.walletAdapter.n3Address,
      }

      const transactionHash = await $.walletAdapter.invokeN3Wallet(invokeParams)

      const isTransactionValid = await NeoHelper.validateTransaction(
        String(transactionHash)
      )

      if (!isTransactionValid) throw new Error()

      this.currentStep = ClaimGasStep.SUCCESS
    } catch (e) {
      console.error(e)
      this.currentStep = ClaimGasStep.UNKNOWN_ERROR
    }
  }
}
