









































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {$} from '@/facade'

@Component({
  computed: {
    $() {
      return $
    },
  },
})
export default class GasCalculatorChangeVoteTooltipContent extends Vue {
  @Prop({type: Boolean, required: true}) useNeoBurger!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isSameCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isNoCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  hasInsufficientNeo!: boolean
  @Prop({type: Boolean, required: true}) useNeoCompounder!: boolean

  async connect() {
    if ($.walletAdapter.n3ConnectedWalletPlatform === null) {
      this.$modal.open('connectWalletModal')
    }
  }
}
