


























































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import {NeoHelper} from '@/helpers/NeoHelper'
import MyWalletClaimGasModal from '@/components/myWallet/MyWalletClaimGasModal.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'
import MyWalletProceedButton from '@/components/myWallet/MyWalletProceedButton.vue'
import MyWalletDisclaimerMobile from '@/components/myWallet/MyWalletDisclaimerMobile.vue'

@Component({
  components: {
    MyWalletDisclaimerMobile,
    MyWalletProceedButton,
    MyWalletClaimGasModal,
  },
})
export default class GasCalculatorUnclaimedGas extends MixinScreenSize {
  unclaimedGas = '0'
  fee = '0'

  openMobileDisclaimer = false

  connectedAddress: string | null = null

  get popoverText() {
    return $.translate('components.MyWalletUnclaimedGas.feeDisclaimer')
  }

  get isFeeGreaterThanUnclaimedGas() {
    return Number(this.fee) >= Number(this.unclaimedGas)
  }

  get isProceedDisabled() {
    return this.isFeeGreaterThanUnclaimedGas
  }

  set isProceedDisabled(value: boolean) {
    this.isProceedDisabled = value
  }

  setOpenMobileDisclaimer(val: boolean) {
    this.openMobileDisclaimer = val
  }

  mounted() {
    this.populateUnclaimedGas()
  }

  openClaimGasModal() {
    this.$modal.open('myWalletClaimGasModal')
  }

  async populateUnclaimedGas() {
    const connectedAddress = this.$walletAdapter.n3Address

    if (!connectedAddress) {
      this.connectedAddress = null
      return
    }

    this.connectedAddress = connectedAddress

    await $.await.run('populateUnclaimedGas', async () => {
      const {unclaimed} = await NeoHelper.getUnclaimedGasData(connectedAddress)
      const {total} = await NeoHelper.calculateUnclaimedGasFee(connectedAddress)

      this.unclaimedGas = (unclaimed * 10 ** -8).toFixed(8)
      this.fee = total.toFixed(8)
    })
  }

  @Watch('$store.state.walletAdapter.n3Address')
  onN3AddressChange(address: string | null) {
    this.populateUnclaimedGas()
  }
}
