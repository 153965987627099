


































































































































import {Component} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {CandidateVoteStep} from '@/enums/CandidateVoteStep'
import CandidateVoteSwappedStep from '@/components/candidateVote/CandidateVoteSwappedStep.vue'
import CandidateVoteInsufficientGasErrorStep from '@/components/candidateVote/CandidateVoteInsufficientGasErrorStep.vue'
import CandidateVoteLoadingTransactionStep from '@/components/candidateVote/CandidateVoteLoadingTransactionStep.vue'
import CandidateVoteUnexpectedErrorStep from '@/components/candidateVote/CandidateVoteUnexpectedErrorStep.vue'
import CandidateVoteDetailsStep from '@/components/candidateVote/CandidateVoteDetailsStep.vue'
import CandidateVoteDetailsCard from '@/components/candidateVote/CandidateVoteDetailsCard.vue'
import {NeoHelper} from '@/helpers/NeoHelper'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {$} from '@/facade'
import {InvokeParams} from '@/model/wallets/types/WalletTypes'

type TOpenEventParams = {
  gasCurrentPriceInDollar: number | null
  selectedCandidateDailyAmount: number | null
  currentCandidateVotedDailyAmount: number | null
  selectedCandidate: GasCalculatorTarget | null
  currentCandidateVoted: GasCalculatorTarget | null
}

@Component({
  components: {
    CandidateVoteSwappedStep,
    CandidateVoteInsufficientGasErrorStep,
    CandidateVoteUnexpectedErrorStep,
    CandidateVoteLoadingTransactionStep,
    CandidateVoteDetailsStep,
    CandidateVoteDetailsCard,
  },
  computed: {
    CandidateVoteStep() {
      return CandidateVoteStep
    },
  },
})
export default class CandidateVoteModal extends MixinScreenSize {
  gasCurrentPriceInDollar: number | null = null
  selectedCandidateDailyAmount: number | null = null
  currentCandidateVotedDailyAmount: number | null = null
  selectedCandidate: GasCalculatorTarget | null = null
  currentCandidateVoted: GasCalculatorTarget | null = null

  currentStep: CandidateVoteStep = CandidateVoteStep.REVIEW_FORM

  get canBeClosed() {
    return (
      this.currentStep !== CandidateVoteStep.WAITING_SIGNATURE &&
      this.currentStep !== CandidateVoteStep.VALIDATING_TRANSACTION
    )
  }

  async openEvent({
    gasCurrentPriceInDollar,
    selectedCandidateDailyAmount,
    currentCandidateVotedDailyAmount,
    selectedCandidate,
    currentCandidateVoted,
  }: TOpenEventParams) {
    this.gasCurrentPriceInDollar = gasCurrentPriceInDollar
    this.selectedCandidateDailyAmount = selectedCandidateDailyAmount
    this.currentCandidateVotedDailyAmount = currentCandidateVotedDailyAmount
    this.selectedCandidate = selectedCandidate
    this.currentCandidateVoted = currentCandidateVoted

    this.currentStep = CandidateVoteStep.REVIEW_FORM
  }

  async closeEvent() {
    this.currentStep = CandidateVoteStep.REVIEW_FORM
  }

  async vote() {
    this.currentStep = CandidateVoteStep.WAITING_SIGNATURE

    try {
      const invokeParams: InvokeParams = {
        method: 'voteInvocation',
        params: {
          candidatePublicKey: this.selectedCandidate?.publicKey ?? null,
        },
      }

      const transactionHash = await $.walletAdapter.invokeN3Wallet(invokeParams)

      this.currentStep = CandidateVoteStep.VALIDATING_TRANSACTION

      const isTransactionValid = await NeoHelper.validateTransaction(
        String(transactionHash)
      )

      if (!isTransactionValid) throw new Error()

      this.currentStep = CandidateVoteStep.SWAPPED
      this.$emit('swapped')
    } catch (e) {
      console.error(e)
      this.currentStep = CandidateVoteStep.UNKNOWN_ERROR
    }
  }
}
