











import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class MyWalletDisclaimerMobile extends MixinScreenSize {
  @Prop({type: String, required: false}) text!: string | undefined
  @Prop({type: Boolean, required: true}) show!: boolean
}
