













































































































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class MyWalletProceedButton extends MixinScreenSize {
  @Prop({type: String, required: true}) titleText!: string
  @Prop({type: String, required: true}) popoverText!: string
  @Prop({type: Boolean, required: false, default: false}) showAsterisk!: boolean
  @Prop({type: Boolean, required: false, default: false}) showArrow!: boolean
  @Prop({type: Boolean, required: false, default: false}) isDisabled!: boolean
  @Prop({type: Boolean, required: false, default: true})
  isBlackVersion!: boolean

  openMobileDisclaimer = false

  onClickMobileAction() {
    if (this.isDisabled) {
      this.openMobileDisclaimer = !this.openMobileDisclaimer
      this.$emit('open-mobile-disclaimer', this.openMobileDisclaimer)
      return
    }

    this.$emit('call-action')
  }

  onClickDesktopAction() {
    if (this.isDisabled) {
      return
    }

    this.$emit('call-action')
  }
}
